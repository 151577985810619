<template>
  <b-form>
    <b-row>
      <b-col md="6">
        <b-form-group label="Выберите перевозчика (владельца ТС)">
          <b-form-select id="carrier" v-model="form.carrierId" :state="formErrors.carrierId">
            <b-form-select-option :value="null" :key="null">Выберите перевозчика</b-form-select-option>
            <b-form-select-option v-for="carrier in carriers" :value="carrier.id" :key="carrier.id">
              {{ carrier.carrierName }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Выберите схему автобуса">
          <b-form-select id="carrier" v-model="form.schemaId" :state="formErrors.schemaId">
            <b-form-select-option :value="null" :key="null">Выберите схему автобуса</b-form-select-option>
            <b-form-select-option v-for="busSchema in busSchemas" :value="busSchema.id" :key="busSchema.id">
              {{ busSchema.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Марка ТС">
          <b-form-input v-model="form.mark" placeholder="Марка ТС" :state="formErrors.mark"/>
        </b-form-group>
        <b-form-group label="Модель ТС">
          <b-form-input v-model="form.model" placeholder="Модель ТС" :state="formErrors.model"/>
        </b-form-group>
        <b-form-group label="Гос номер ТС">
          <b-form-input v-model="form.gosNumber" placeholder="Гос номер ТС" :state="formErrors.gosNumber" :formatter="gosNumberFormat"/>
        </b-form-group>
        <b-form-group label="Гаражный номер">
          <b-form-input v-model="form.garageNumber" placeholder="Гаражный номер" :state="formErrors.garageNumber"/>
        </b-form-group>
        <b-form-group label="Год выпуска">
          <b-form-input type="number" v-model="form.yearIssue" placeholder="Год выпуска" :state="formErrors.yearIssue"/>
        </b-form-group>
        <b-form-group label="Цвет">
          <b-form-input v-model="form.color" placeholder="Цвет" :state="formErrors.color"/>
        </b-form-group>
        <b-form-group label="Телефон для связи с водителем">
          <b-form-input v-model="form.phone" placeholder="Телефон для связи с водителем" :state="formErrors.phone" :formatter="phoneFormat"/>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Дополнительно">
          <b-form-checkbox v-model="form.TV">
            Наличие ТВ
          </b-form-checkbox>
          <b-form-checkbox v-model="form.conditioner">
            Наличие кондиционера
          </b-form-checkbox>
          <b-form-checkbox v-model="form.mayPassBike">
            Можно провести велосипед
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label="Фотографии">
          <b-form-file class="mb-2" placeholder="Выберите файл" browse-text="Выбрать"/>
          <b-form-file class="mb-2" placeholder="Выберите файл" browse-text="Выбрать"/>
          <b-form-file class="mb-2" placeholder="Выберите файл" browse-text="Выбрать"/>
          <b-form-file class="mb-2" placeholder="Выберите файл" browse-text="Выбрать"/>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox v-model="form.isPersonalBus">
            Личное ТС
          </b-form-checkbox>
          <b-form-checkbox v-model="form.isNewBus">
            Показывать иконку "Новый автобус"
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label="Количество стоящих мест">
          <b-form-input type="number" v-model.number="form.standPlaceCount" placeholder="Количество стоящих мест" :state="formErrors.standPlaceCount"/>
          <b-form-text>Для пригородных маршрутов</b-form-text>
        </b-form-group>
        <b-form-group label="Грузоподъемность">
          <b-form-input type="number" v-model.number="form.carryingCapacity" placeholder="Грузоподъемность" :state="formErrors.carryingCapacity"/>
          <b-form-text>Грузоподъемность, в тоннах</b-form-text>
        </b-form-group>
        <b-form-group label="Объем грузового отделения">
          <b-form-input type="number" v-model.number="form.cargoCompartmentVolume" placeholder="Объем грузового отделения" :state="formErrors.cargoCompartmentVolume"/>
          <b-form-text>Объем грузового отделения, в м3</b-form-text>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import validate from "@/utils/formValidator";
import VEHICLE from "@/forms/VEHICLE";
import {mapState} from "vuex";
import {phoneFormat} from "@/utils/phone";

const VEHICLE_INIT_FORM = () => ({
  mark: '',
  model: '',
  gosNumber: '',
  carrierId: null,
  schemaId: null,
  garageNumber: '',
  yearIssue: null,
  color: '',
  phone: '',
  suburbanGlonassIdentity: null,
  glonassId: null,
  TV: false,
  conditioner: false,
  mayPassBike: false,
  isPersonalBus: false,
  isNewBus: false,
  standPlaceCount: null,
  carryingCapacity: null,
  cargoCompartmentVolume: null
})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => VEHICLE_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      form: this.init,
      errors: {}
    }
  },
  watch: {
    form: {
      handler: function () {
        this.validateForm();
      },
      deep: true
    }
  },
  computed: {
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    },
    ...mapState({
      carriers: state => state.Carriers.items,
      busSchemas: state => state.VehicleSchemas.items
    })
  },
  methods: {
    phoneFormat: (value) => phoneFormat(value),
    gosNumberFormat(value) {
      return value.toUpperCase().replace(/[А-Я]/g, '')
    },
    removeAt(idx) {
      this.form.points.splice(idx, 1);
    },
    fillForm(form) {
        const enabledKeys = Object.keys(VEHICLE_INIT_FORM())
        this.form = Object.fromEntries(Object.entries(form).filter(([key]) => ['id', ...enabledKeys].includes(key)));
    },
    resetForm() {
      Object.assign(this.form, VEHICLE_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, VEHICLE)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      if (!this.validateForm())
        return

      this.$emit('onSave', { ...this.form });
    }
  }
}
</script>

<style scoped>
  .remove-icon-style {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
</style>