const NOT_EMPTY = 'Поле обязательно для заполнения.';
const INCORRECT_PATTERN = 'Не соответствует шаблону.';
const MIN_LENGTH = 'Символов не может быть меньше чем {0}.';
const MAX_LENGTH = 'Символов не должно быть больше чем {0}.';
const NOT_EQUALS = 'Не может быть равно данному значению.';
const NOT_EQUALS_FIELD = 'Не может быть равно данному значению.';

let options = {
    pattern: {
        errorText: INCORRECT_PATTERN,
        handle(value, pattern) {
            if (typeof pattern === 'string')
                pattern = new RegExp(pattern);

            if (!(pattern instanceof RegExp))
                throw new Error('pattern must be RegExp.');

            return pattern.test(value);
        }
    },
    minLength: {
        errorText: MIN_LENGTH,
        handle(value, defaultValue) {
            return value.length >= defaultValue;
        }
    },
    maxLength: {
        errorText: MAX_LENGTH,
        handle(value, defaultValue) {
            return value.length <= defaultValue;
        }
    },
    errorWhenEquals: {
        errorText: NOT_EQUALS,
        handle(value, defaultValue) {
            return value !== defaultValue;
        }
    },
    equalsField: {
        errorText: NOT_EQUALS_FIELD,
        handle(value, defaultValue, data) {
            return value === data[defaultValue];
        }
    },
    customValidator: {
        errorText: null,
        handle(value, defaultValue, data) {
            console.log('test')
            return defaultValue.callback(value, data);
        }
    }
}

function isRequired(schema) {
    for (let rule of schema) {
        if (rule.required === true)
            return true;
    }

    return false;
}

function isCustomValidation(schema) {
    for (let rule of schema) {
        if (rule.customValidator)
            return true;
    }

    return false;
}

function handleOption(schemaRule, value, data) {
    let fieldKey;
    for (let field in schemaRule) {
        fieldKey = field;
        if (options[fieldKey]) break;
    }

    if (!options[fieldKey]) {
        return {
            result: true
        }
    }

    let result = options[fieldKey].handle(value, schemaRule[fieldKey], data);

    let errorText = schemaRule.errorText ? schemaRule.errorText : options[fieldKey].errorText;
    errorText = errorText.replace(/\{0\}/g, schemaRule[fieldKey]).replace(/\{1\}/g, schemaRule[value]);

    if (!result)
        return {
            result: false,
            error: errorText
        }

    return {
        result: true,
    }
}

export default function validate(data, schema) {
    let result = {};
    for (let field in schema) {
        let value = data[field];
        if (value && value !== '0' || isCustomValidation(schema[field])) {
            for (let schemaFieldItem of schema[field]) { // object
                let fieldResult = {};
                fieldResult = handleOption(schemaFieldItem, value, data);
                if (!fieldResult.result) {
                    result[field] = fieldResult.error;
                    break;
                }
            }
        } else if (isRequired(schema[field])) {
            result[field] = NOT_EMPTY;
        }
    }

    return result;
}