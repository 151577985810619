<template>
  <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Добавить ТС"
      hide-footer
      size="lg"
  >
    <Form ref="form" btn-title="Добавить" btn-variant="primary" @onSave="createVehicle"/>
  </b-modal>
</template>

<script>
import Form from './Form'

export default {
  name: "Create",
  methods: {
    show() {
      this.$refs.modal.show()
    },
    createVehicle(form) {
      console.log('form')
      console.log(form)
      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('Vehicles/create', form)
          .then(() => {
            this.$refs.modal.hide()
            this.$refs.form.resetForm()
            this.$bvToast.toast('Транспортное средство было успешно создано.', {
              title: 'Успех',
              autoHideDelay: 5000,
              variant: 'success',
              appendToast: true
            })
          })
          .finally(() => {
            this.$store.dispatch('App/stopLoad')
          })
    }
  },
  components: {
    Form
  }
}
</script>

<style scoped>

</style>