<template>
  <div>
    <b-col cols="12">
      <h2>Транспортные средства</h2>
    </b-col>
    <b-col cols="12" class="mb-4 d-flex justify-content-end">
      <b-button variant="primary" @click="$refs.createModal.show()">Добавить ТС</b-button>
    </b-col>
    <create ref="createModal"/>
    <update ref="updateModal"/>
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
        <b-card class="w-100 mb-4">
          <b-table :items="vehicles" :fields="fields" :small="true">
            <template #cell(actions)="data">
              <div class="text-center">
                <b-icon class="action-icon" icon="pencil-fill" variant="primary" font-scale="1.2" @click="update(data.item)" title="Редактировать"></b-icon>
                <b-icon class="action-icon" icon="trash-fill" variant="danger" font-scale="1.2" @click="remove(data.item.id)" title="Удалить"></b-icon>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import {mapState} from "vuex";

import Create from './Vehicles/Create'
import Update from './Vehicles/Update'

export default {
  name: "Vehicles",
  data() {
    return {
      fields: [
        {
          key: 'gosNumber',
          label: 'Гос. Номер'
        },
        {
          key: 'garageNumber',
          label: 'Гаражный номер'
        },
        {
          key: 'mark',
          label: 'Марка'
        },
        {
          key: 'model',
          label: 'Модель'
        },
        {
          key: 'actions',
          label: 'Действия'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      loading: state => state.Vehicles.loading,
      vehicles: state => state.Vehicles.items
    })
  },
  mounted() {
    this.$store.dispatch('Vehicles/load', { page: 1 })
  },
  methods: {
    remove(id) {
      if (!id)
        return

      if (!confirm('Вы действительно хотите удалить ТС?'))
        return

      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('Vehicles/remove', id)
          .then(() => {
            this.$bvToast.toast('ТС было успешно удалено.', {
              title: 'Успех',
              autoHideDelay: 5000,
              variant: 'success',
              appendToast: true
            })
          })
          .finally(() => {
            this.$store.dispatch('App/stopLoad')
          })
    },
    update(form) {
      console.log(form);
      this.$refs.updateModal.show(form)
    }
  },
  components: {
    Create,
    Update
  }
}
</script>

<style scoped>
.action-icon {
  margin: 4px;
  cursor: pointer;
}
</style>